<template>
  <div class="main-div">
    <div class="mobile-extra shadow">
      <menu-logotipo :on-menu="$route.name !== 'Home' && $route.name !== 'Solutions'" />
      <menu-button :button-theme="currentButtonTheme" :onClick="openMenuMobile"/>
    </div>
    <!-- end top options -->
    <main class="menu-extended">
      <div ref="menu" :class="`menu--links ${currentTheme}`">
        <div class="mobile-extra">
          <menu-logotipo :on-menu="$route.name !== 'Cases' && $route.name !== 'People'" />
          <menu-button :onClick="closeMenuMobile" :buttonTheme="openMenuColor"/>
        </div>
        <div class="container-menu">
          <menu-text :menu-texts="menuTexts"></menu-text>
        </div>
        <div class="social-medias-bottom" ref="iPhone">
          <menu-links />
          <menu-i18n />
        </div>
      </div>
      <div :class="`menu--bar ${currentTheme}`">
        <div class="buttonsTop">
          <menu-button v-if="!show" :onClick="openMenu" :buttonTheme="openMenuColor"/>
          <template v-else>
            <menu-button :onClick="closeMenu" :buttonTheme="openMenuColor" />
          </template>
        </div>
        <menu-links />
        <div class="footer">
          <menu-chat
            @open-website="toggleIframe"
            @open-messenger="toggleMessenger"
            @open-whatsapp="openWhatsApp"
          />
          <menu-i18n />
        </div>
      </div>

      <!-- frame chat -->
      <div :class="['frame', { 'opened': showIframe }]">
        <div class="border-radius">
          <!-- <iframe src="https://omnichannels.ucall.co.ao/ucall/form_chat_teste.html"></iframe> -->
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { TweenLite, TimelineMax } from 'gsap'

import MenuChat from './MenuChat'
import MenuText from './MenuText'
import MenuI18n from './MenuI18n'
import MenuLinks from './MenuLinks'
import MenuLogotipo from './MenuLogotipo'
import MenuButton from './MenuButtonClose'
import MenuRouteLink from '@/utils/menu-route-links'

export default {
  name: 'menuComponent',
  watch: {
    $route: {
      handler: function () {
        if (window.screen.width >= 760) {
          this.closeMenu()
        } else {
          this.closeMenuMobile()
        }

        if (this.$route.name === 'People' && window.screen.width <= 560) {
          if (document.querySelector('.shadow')) document.querySelector('.shadow').style.backgroundColor = '#fafafa'
        } else {
          if (document.querySelector('.shadow')) document.querySelector('.shadow').style.backgroundColor = 'transparent'
        }

        this.openMenuColor = ['Home', 'Solutions', 'Responsibility', 'Login', 'Recover', 'People'].includes(this.$route.name) ? 'btn-menu-dark' : 'btn-menu-light'
        if (this.$refs['menu']) {
          this.$refs['menu'].className = `menu--links  ${this.currentTheme}`
        }

        if ((this.$route.name === 'Cases') || (this.$route.name === 'People')) {
          this.$store.commit('menu/SET_BUTTON_THEME', 'btn-menu-dark')
          return
        }

        if ((this.$route.name === 'ResponsibilityView')) {
          this.$store.commit('menu/ThemeResponsability', 'btn-menu-light')
          return
        }
        this.$store.commit('menu/SET_BUTTON_THEME', 'btn-menu-light')
      },
      immediate: true
    }
  },
  components: {
    MenuChat,
    'menu-text': MenuText,
    'menu-links': MenuLinks,
    'menu-logotipo': MenuLogotipo,
    'menu-button': MenuButton,
    'menu-i18n': MenuI18n
  },
  data () {
    return {
      show: true,
      menuTexts: MenuRouteLink,
      openMenuColor: 'btn-menu-dark',
      showIframe: false,
      showFacebook: false
    }
  },
  mounted () {
    if (navigator.userAgent.includes('iPhone')) {
      this.$refs.iPhone.style.marginBottom = '35px'
    }

    if (this.$route.name === 'People' && window.screen.width <= 560) {
      document.querySelector('.shadow').style.backgroundColor = '#fafafa'
    }
  },
  methods: {
    openMenu () {
      const tweens = new TimelineMax()
      this.openAnimation(tweens)
      tweens.to('.buttonsTop', 0.3, { marginTop: 4 })
      if (window.matchMedia('(min-width: 1023px)').matches) { TweenLite.to('.menu--links', 0.1, { width: '50vw' }) } else { TweenLite.to('.menu--links', 0.2, { width: '90vw' }) }
      this.toggle()
    },
    openMenuMobile () {
      const tweens = new TimelineMax()
      this.openAnimation(tweens)
      tweens.to('.menu--links', 0.2, { height: '100%' })
      tweens.to('.menu-extended', 0.8, {
        position: 'fixed',
        top: 0,
        height: '90vh'
      })
      TweenLite.to('.tree-lines-last', 0.5, {
        top: 20
      })
      tweens.to('.social-medias-bottom', 0.5, { display: 'flex', opacity: 1 })
    },
    openAnimation (tweens) {
      tweens.to('.tree-lines-middle', 0, { opacity: 0, display: 'none' })
      TweenLite.to('.tree-lines-last', 0.5, {
        transform: 'rotate(45deg)',
        position: 'absolute',
        top: 0
      })
      TweenLite.to('.tree-lines-first', 0.5, { transform: 'rotate(-45deg)' })
    },
    closeMenu () {
      const tweens = new TimelineMax()
      tweens.to('.buttonsTop', 0.3, { marginTop: 0 })
      TweenLite.to('.menu--links', 0.1, { width: '0vw' })
      this.closeAnimation(tweens)
      this.toggle()
    },
    closeAnimation (tweens) {
      TweenLite.to('.tree-lines-first', 0.1, { transform: 'rotate(0deg)' })
      TweenLite.to('.tree-lines-last', 0.5, {
        position: 'relative',
        transform: 'rotate(0deg)'
      })
      TweenLite.to('.tree-lines-middle', 0.5, { opacity: 1, display: 'block' })
    },
    closeMenuMobile () {
      const tweens = new TimelineMax()
      tweens.to('.social-medias-bottom', 0.2, { display: 'none', opacity: 0 })
      tweens.to('.menu-extended', 1, { height: 0 })
      TweenLite.to('.tree-lines-last', 0.5, {
        top: 0
      })
      this.closeAnimation(tweens)
    },
    toggle () {
      this.show = !this.show
    },
    toggleIframe () {
      // this.showIframe = !this.showIframe
      // if (this.showIframe) {
      // eslint-disable-next-line
      window.FB.CustomerChat.hide()
      if (window.xgen) {
        window.xgen.maximizeEmbed()
      }
      // }
    },
    toggleMessenger () {
      if (window.xgen) {
        window.xgen.closeEmbed()
      }
      window.FB.CustomerChat.showDialog()
    },
    openWhatsApp () {
      window.open(`https://api.whatsapp.com/send?phone=${this.appConfig.whatsapp}`, '__blank')
    }
  },
  computed: {
    ...mapGetters('menu', ['currentTheme', 'currentButtonTheme']),
    appConfig () {
      return this.$store.getters.appConfig
    }
  }
}
</script>
<style lang="scss" scoped>
.main-div {
  width: 100vw;
  z-index: 11;
  position: fixed;
  top: 30px;
  .mobile-extra {
    display: flex;
    justify-content: space-between;
    /* padding: 16px 40px; */
  }
  .menu-extended {
    height: 0;
    overflow: hidden;
  }
}
.menu--bar {
  display: none;
  position: relative;
}
.menu--links {
  overflow: hidden;

  .mobile-extra {
    margin-top: 2vh;
    /* padding: 16px 40px;
    padding-right: 45px;
*/
    a {
      align-items:  flex-start;
    }
  }
}
.btn-menu-dark {
  color: #3c3c3c !important;
}
.btn-menu-light {
  color: #fff !important;
}
@media (max-width: 420px) {
  .mobile-extra {
    /* padding: 16px !important; */
    margin: 0 16px;
    /* margin-top: 22px; */
  }
}
@media (min-width: 960px) {
  .mobile-extra {
    display: none !important;
  }

  .main-div {
    position: fixed;
    left: 0;
    top: 0;
    top: 11%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    main.menu-extended {
      display: flex;
      height: 80vh;
      top: 10%;
      position: absolute;
      box-shadow: 0 15px 12px rgba($color: #000000, $alpha: 0.09);
      right: 5px;
      overflow: visible;

      .menu--links {
        width: 0vw;
        height: inherit;
        overflow: hidden;
        transition: width 0.5s ease-in-out;
      }

      .menu--bar {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        padding: 2vh 0;
        height: 100%;
        width: 10vw;
        box-sizing: border-box;
      }

      .frame {
        background-color: #fff;
        box-shadow: -10px -6px 12px rgba($color: #000000, $alpha: 0.09);
        height: 80%;
        position: absolute;
        // top: 40h;
        right: 100%;
        bottom: 0.5px;
        padding: 0px;
        // display: none;

        .border-radius {
          height: 100%;
          border-radius: 12px;
          iframe {
            height: 100%;
            width: 100%;
            border: 0;
          }
        }
      }
    }
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
@media (min-width: 1023px) {
  .menu--bar {
    width: 7vw !important;
  }

  .menu-extended {
    right: 50px !important;
  }
}
@media (min-width: 1240px) {
  .menu--bar {
    width: 7vw !important;
  }
}
@media (min-width: 1440px) {
  .menu--bar {
    width: 5vw !important;
  }
}
.social-medias-bottom {
  position: absolute;
  bottom: 10px;
  display: none;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
  width: 100vw;
  opacity: 0;
}
.go-back {
  font-size: 18px;
  font-weight: 100;
}
</style>
<style lang="scss">
.ThemeHome, .ThemeContacts {
  background-color: #fff;
}
.ThemePeople {
  background-color: #fff;
}
.ThemeContacts {
  background-color: #fff;
}
.ThemeResponsability {
  background-color: #51BFED;
}
.ThemeSolutions {
  background-color: #e18697;
}
.ThemeCases {
  background-color: #fec054;
}

a {
  color: #3c3c3c !important;
}

.ThemeSolutions .menu_social_network a {
  color: #fff;
}
</style>
