<template>
  <div class="default-layout">
      <router-link :to="{ name: 'Home' }">
        <!-- <img v-if="$route.name === 'About'" class="about-logo" src="@/assets/ucall_logo.svg" alt="Ucall Logo"> -->
        <img v-if="currentNameRoute =='About'" src="@/assets/logo.svg" class="img logo" alt="ucall logo" />
        <ImgSVG v-else :src="ucallLogo" class="img logo" alt="ucall logo" />
      </router-link>
      <app-menu />
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MenuThemeConfigs from '@/utils/menu-route-theme'
import MenuComponent from '@/components/Menu/MenuComponent'
import ImgSVG from '@/components/ImgSVG'
export default {
  name: 'DefaultLayout',
  metaInfo: {
    htmlAttrs: {
      lang: 'pt'
    }
  },
  watch: {
    '$i18n.locale': {
      handler: async function (after, before) {
        await this.$store.dispatch('setAppConfig', { lang: this.$i18n.locale })
      },
      deep: true
    },
    $route: {
      handler: function () {
        let routeTheme = this.themesConfig.filter(item => item.route === this.$route.name)[0].theme
        this.$store.commit('menu/SET_MENU_THEME', routeTheme)
        // <<<<<<< HEAD
        //         this.ucallLogo = ['People', 'Cases'].includes(this.$route.name) ? require('@/assets/ucall_logo.svg') : require('@/assets/logo_branco.svg')
        //         this.hideLogo()
        // =======
        this.$store.dispatch('about/setRouteName', this.$route.name)
        this.ucallLogo = require('@/assets/logo.svg')
        const body = document.body
        if (['Cases', 'People'].includes(this.$route.name)) {
          body.classList.remove('custom-brand')
          body.style.setProperty('--brand-color', '')
        } else {
          body.classList.add('custom-brand')
          body.style.setProperty('--brand-color', '#fff')
        }
        // this.ucallLogo = ['Cases', 'People'].includes(this.$route.name) ? require('@/assets/ucall_logo.svg') : require('@/assets/logo_branco.svg')
        // this.hideLogo()
        /* const menu = document.querySelector('.menu')
        const logo = document.querySelector('.logo')
        if (logo) {
          if (this.$route.name === 'Login' || this.$route.name === 'Recover') {
            logo.style.visibility = 'hidden'
          } else {
            logo.style.visibility = 'inherit'
          }
        }
        if (menu) {
          if (menu && this.$route.name === 'Case') {
            menu.style.display = 'none'
            return
          }
          menu.style.display = 'block'
        } */
      },
      immediate: true
    }
  },
  components: {
    'app-menu': MenuComponent,
    ImgSVG
  },
  data () {
    return {
      themesConfig: MenuThemeConfigs,
      ucallLogo: '',
      nameRoute: ''
    }
  },
  computed: {
    ...mapGetters('about', ['currentNameRoute'])
  },
  created () {
    this.nameRoute = this.currentNameRoute
  },
  async mounted () {
    // this.hideLogo()
    await this.$store.dispatch('setAppConfig', { lang: this.$i18n.locale })
    await this.$store.dispatch('about/setRouteName', this.$route.name)
    this.nameRoute = this.currentNameRoute
  },
  methods: {
    alterNameRoute () {
    },
    hideLogo () {
      const el = document.querySelector('#menu')
      const logo = document.querySelector('.logo')
      if ((logo && this.$route.name === 'Login') || (logo && this.$route.name === 'Recover')) {
        document.querySelector('.logo').style.visibility = 'hidden'
        return
      }
      if (el) {
        if (el && this.$route.name === 'Case') {
          document.querySelector('#menu').style.display = 'none'
          return
        }
        document.querySelector('#menu').style.display = 'block'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
header {
  position: fixed;
  width: 100%;
  z-index: 999;
  display: inline-flex;
  padding-top: 2vh;
}
.router-link-active {
  z-index: 9;
  position: fixed
}

.default-layout {
  height: 100vh;
}

img.logo, .img.logo {
  margin: 0;
  display: none;
  transition: all .26s linear;
}

@media (min-width: 960px) {
  img.logo, .img.logo {
    display: block !important;
    height: auto;
    width: 230px;
    position: relative;
    margin-top: 1.2em;
    margin-left: 4em;
  }
}
</style>
